<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="10">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.msd_code" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.msd_code" 
                    :rules="toValidate(mrValidation.msd_code)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.msd_name"/>
                  <VValidate 
                    name="Nama" 
                    v-model="row.msd_name" 
                    :rules="toValidate(mrValidation.msd_name)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Kategori<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Pilih Kategori SDKI" @input="doSelectSub" v-model="row.msd_mksd_id" 
                  :disabled="$parent.pageSlug !== 'add'? true : false" :options="$parent.mKategoriSDKI" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  <VValidate 
                    name="Kategori" 
                    v-model="row.msd_mksd_id" 
                    :rules="toValidate(mrValidation.msd_mksd_id)"
                  />
                </b-form-group>
              </b-col>

              
              <b-col lg="6">
                <b-form-group>
                  <label>Sub Kategori<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Pilih Sub Kategori SDKI" v-model="row.msd_msksd_id" :disabled="$parent.pageSlug !== 'add'? true : false" :options="$parent.mSubKategoriSDKI" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  <VValidate 
                    name="Sub Kategori" 
                    v-model="row.msd_msksd_id" 
                    :rules="toValidate(mrValidation.msd_msksd_id)"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col lg="12">
                <label for="">Penyebab</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr class="table-secondary">
                      <th>Nama</th>
                      <th width="100">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.msd_penyebab" :key="k">
                      <td>
                      <input type="text" v-model="row['msd_penyebab'][k]['name']" class="form-control" placeholder="e.g. Penyebab">
                      <VValidate 
                        :name="'Nama Penyebab '+(k+1)" 
                        v-model="row['msd_penyebab'][k]['name']" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1}"
                      />
                      </td>
                      <td class="text-center">
                        <i @click="removeRowPenyebab(k)" class="icon-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">
                        <div class="text-center">
                          <button type="button" @click="addRowPenyebab()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>
            
            <b-row class="mb-3">
              <b-col lg="12">
                <label for="">Faktor Resiko</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr class="table-secondary">
                      <th>Nama</th>
                      <th width="100">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.msd_faktor_resiko" :key="k">
                      <td>
                      <input type="text" v-model="row['msd_faktor_resiko'][k]['name']" class="form-control" placeholder="e.g. Faktor Resiko">
                      <VValidate 
                        :name="'Nama Faktor Resiko '+(k+1)" 
                        v-model="row['msd_faktor_resiko'][k]['name']" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1}"
                      />
                      </td>
                      <td class="text-center">
                        <i @click="removeRowFaktorResiko(k)" class="icon-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">
                        <div class="text-center">
                          <button type="button" @click="addRowFaktorResiko()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>
            
            <b-row class="mb-3">
              <b-col lg="12">
                <label for="">Gejala Mayor Subjektif</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr class="table-secondary">
                      <th>Nama</th>
                      <th width="100">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.msd_gejala_mayor_subjektif" :key="k">
                      <td>
                      <input type="text" v-model="row['msd_gejala_mayor_subjektif'][k]['name']" class="form-control" placeholder="e.g. Gejala Mayor Subjektif">
                      <VValidate 
                        :name="'Nama Gejala Mayor Subjektif '+(k+1)" 
                        v-model="row['msd_gejala_mayor_subjektif'][k]['name']" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1}"
                      />
                      </td>
                      <td class="text-center">
                        <i @click="removeRowGejalaMayorSubjektif(k)" class="icon-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">
                        <div class="text-center">
                          <button type="button" @click="addRowGejalaMayorSubjektif()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col lg="12">
                <label for="">Gejala Mayor Objektif</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr class="table-secondary">
                      <th>Nama</th>
                      <th width="100">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.msd_gejala_mayor_objektif" :key="k">
                      <td>
                      <input type="text" v-model="row['msd_gejala_mayor_objektif'][k]['name']" class="form-control" placeholder="e.g. Gejala Mayor Objektif">
                      <VValidate 
                        :name="'Nama Gejala Mayor Objektif '+(k+1)" 
                        v-model="row['msd_gejala_mayor_objektif'][k]['name']" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1}"
                      />
                      </td>
                      <td class="text-center">
                        <i @click="removeRowGejalaMayorObjektif(k)" class="icon-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">
                        <div class="text-center">
                          <button type="button" @click="addRowGejalaMayorObjektif()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col lg="12">
                <label for="">Gejala Minor Subjektif</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr class="table-secondary">
                      <th>Nama</th>
                      <th width="100">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.msd_gejala_minor_subjektif" :key="k">
                      <td>
                      <input type="text" v-model="row['msd_gejala_minor_subjektif'][k]['name']" class="form-control" placeholder="e.g. Gejala Minor Subjektif">
                      <VValidate 
                        :name="'Nama Gejala Minor Subjektif '+(k+1)" 
                        v-model="row['msd_gejala_minor_subjektif'][k]['name']" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1}"
                      />
                      </td>
                      <td class="text-center">
                        <i @click="removeRowGejalaMinorSubjektif(k)" class="icon-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">
                        <div class="text-center">
                          <button type="button" @click="addRowGejalaMinorSubjektif()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>
            
            <b-row class="mb-3">
              <b-col lg="12">
                <label for="">Gejala Minor Objektif</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr class="table-secondary">
                      <th>Nama</th>
                      <th width="100">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.msd_gejala_minor_objektif" :key="k">
                      <td>
                      <input type="text" v-model="row['msd_gejala_minor_objektif'][k]['name']" class="form-control" placeholder="e.g. Gejala Minor Objektif">
                      <VValidate 
                        :name="'Nama Gejala Minor Objektif '+(k+1)" 
                        v-model="row['msd_gejala_minor_objektif'][k]['name']" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1}"
                      />
                      </td>
                      <td class="text-center">
                        <i @click="removeRowGejalaMinorObjektif(k)" class="icon-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">
                        <div class="text-center">
                          <button type="button" @click="addRowGejalaMinorObjektif()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>
          
            <b-row>              
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    
    doSelectSub(){
      Gen.apiRest(
          "/do/"+this.$parent.modulePage,
          {data:{type:'select-sub', id: this.row.msd_mksd_id}}, 
          "POST"
      ).then(res=>{
        this.$parent.mSubKategoriSDKI = res.data.data 
        if(!this.$parent.mSubKategoriSDKI.length) this.row.msd_msksd_id = null
      })
    },
    
    addRowPenyebab(){
      let payload = {
        "name":"",
      }
      this.row.msd_penyebab.push(payload)
    },
    removeRowPenyebab(k){
      this.row.msd_penyebab.splice(k,1)      
    },

    addRowGejalaMayorSubjektif(){
      let payload = {
        "name":"",
      }
      this.row.msd_gejala_mayor_subjektif.push(payload)
    },
    removeRowGejalaMayorSubjektif(k){
      this.row.msd_gejala_mayor_subjektif.splice(k,1)      
    },
    
    addRowGejalaMayorObjektif(){
      let payload = {
        "name":"",
      }
      this.row.msd_gejala_mayor_objektif.push(payload)
    },
    removeRowGejalaMayorObjektif(k){
      this.row.msd_gejala_mayor_objektif.splice(k,1)      
    },
    
    addRowGejalaMinorSubjektif(){
      let payload = {
        "name":"",
      }
      this.row.msd_gejala_minor_subjektif.push(payload)
    },
    removeRowGejalaMinorSubjektif(k){
      this.row.msd_gejala_minor_subjektif.splice(k,1)      
    },

    addRowGejalaMinorObjektif(){
      let payload = {
        "name":"",
      }
      this.row.msd_gejala_minor_objektif.push(payload)
    },
    removeRowGejalaMinorObjektif(k){
      this.row.msd_gejala_minor_objektif.splice(k,1)      
    },
    addRowFaktorResiko(){
      let payload = {
        "name":"",
      }
      this.row.msd_faktor_resiko.push(payload)
    },
    removeRowFaktorResiko(k){
      this.row.msd_faktor_resiko.splice(k,1)      
    },


  },
}
</script>